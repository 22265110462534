import React, {useContext} from "react"
import {SnackbarStoreContext} from "../context/SnackbarStore";
import {Progress} from "semantic-ui-react";

export const ProgressMessage = ({}) => {
    const {percent} = useContext(SnackbarStoreContext);
    return <div style={{display: 'flex', flexDirection: 'column'}}>
        <p dir={"rtl"}>מעלה מדיה...</p>
        <Progress percent={percent} indicating/>
    </div>
};