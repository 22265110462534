import React from 'react';
import {UserStoreContext, useUserStore} from "./context/UserStore";
import './App.css';
import {PageContainer} from "./pages/PageContainer";


export const eventTime = Date.UTC(2020, 3, 28, 21);

const App = () => {
    const store = useUserStore();

    return <UserStoreContext.Provider value={store}>
        <PageContainer/>
    </UserStoreContext.Provider>
};

export default App;
