import React, {useContext, useState} from "react";
import {CenteredGroup} from "../../../components/CenteredGroup";

import './style.css'
import {sendText} from "../../../apis/firebase";
import {UserStoreContext} from "../../../context/UserStore";
import {SnackbarStoreContext} from "../../../context/SnackbarStore";

export const TextInput = () => {
    const userStore = useContext(UserStoreContext);
    const snackbarStore = useContext(SnackbarStoreContext);
    const [text, setText] = useState("");
    const send = () => {
        if (text && text.trim() !== '') {
            sendText(userStore.user, "" + text)
                .then(() => snackbarStore.success())
                .catch(e => {
                    console.error(e);
                    alert("error sending message");
                });
            setText("")
        } else {
            snackbarStore.display({timeout: 1500, height: '70px', component: <p dir={"rtl"}>הקלד הודעה!</p>})
        }
    };

    return <div className="form-space" style={{marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <h2 className="base-font" style={{fontWeight: 'normal'}} dir={"rtl"}>בואו נשמע אתכם</h2>
            <textarea rows={2} dir={"rtl"}
                      placeholder={"כתבו פה‎"}
                      onChange={e => setText(e.target.value)}
                      className="message-text-area" value={text}/>
            <div className="message-send-button" onClick={send}>
                <img className="noselect" src={"send.svg"}/>
                <div className="noselect" style={{alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%'}}>
                    שלחו
                </div>
            </div>
        </div>
    </div>
};
