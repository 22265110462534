import React from "react";
import PropTypes from "prop-types";
import {RoundButton} from "./RoundButton";

const EmojiButton = ({name, style, onClick}) => (
    <RoundButton style={style} onClick={() => {
        if (onClick) onClick(`${name}.png`)
    }}>
        <img style={{maxWidth: '49px', maxHeight: '49px'}} src={`/${name}.svg`}/>
    </RoundButton>
);

EmojiButton.propTypes = {
    name: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
};

export {
    EmojiButton
}