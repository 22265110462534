import React, {useRef} from "react"
import PropTypes from "prop-types"
import {RoundButton} from "./RoundButton";
import {Icon} from "semantic-ui-react";

const FileInputButton = ({label, icon, dir, accept, style, onFile}) => {
    const inputRef = useRef(null);

    const openFileDialog = () => {
        inputRef.current.click();
    };

    const readFile = e => {
        const file = e.target.files[0];
        if (file) {
            const name = file.name;
            if (onFile) onFile({name, blob: file})
        }
        inputRef.current.value = ""
    };

    return <div style={style}>
        <input accept={accept} type={"file"} onChange={readFile} ref={inputRef} style={{display: 'none'}}/>
        <RoundButton label={label} dir={dir} onClick={openFileDialog}><img src={icon}/></RoundButton>
    </div>

};

FileInputButton.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    dir: PropTypes.string,
    accept: PropTypes.string,
    style: PropTypes.string,
    onFile: PropTypes.func
};

export {
    FileInputButton
}