import React, {createContext, useState} from "react"
import {SuccessMessage} from "../components/SuccessMessage";
import {ProgressMessage} from "../components/ProgressMessage";

export const SnackbarStoreContext = createContext({});

export const useSnackbarStore = () => {
    const [timeout, setTimeout] = useState(0);
    const [open, setOpen] = useState(false);
    const [component, setComponent] = useState("");
    const [percent, setPercent] = useState(0);
    const [height, setHeight] = useState('');

    const display = ({timeout, component, height}) => {
        setOpen(true);
        setTimeout(timeout);
        setComponent(component);
        setHeight(height);
    };


    const hide = () => setOpen(false);

    const success = () => {
        display({
            timeout: 1500,
            component: <SuccessMessage/>
        })
    };

    const progress = () => {
        display({
            component: <ProgressMessage/>
        })
    };

    return {
        timeout, open, component, display, hide, setOpen, percent, setPercent, success, progress, height, setHeight
    }
};