import React, {useEffect, useRef, useState} from "react"

import "./style.css"


const Snackbar = ({open, timeout, setOpen, height, children}) => {
    const snackbarRef = useRef();
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        if (open) {
            setHidden(false);
            if (timeout && timeout > 0) {
                setTimeout(() => {
                    setOpen(false);
                }, timeout)
            }
        } else {
            if (!hidden) setTimeout(() => setHidden(true), 500);
        }
    });

    const getWidth = () => {
        if (snackbarRef.current) {
            return snackbarRef.current.offsetWidth;
        }
        return 0
    };

    const getSnackbarClasses = () => {
        if (open) return "snackbar on show";
        if (!open && !hidden) return "snackbar hide";
        if (!open && hidden) return "snackbar"
    };
    return <div className="snackbar-overlay" style={{
        visibility: open ? 'visible' : 'hidden'
    }}>
        <div ref={snackbarRef} className={getSnackbarClasses()} style={{
            marginLeft: `calc(50% - ${getWidth() / 2}px)`,
            height: height ? height : '',
        }}>
            {children}
        </div>
    </div>
};

export {
    Snackbar
}