import React from "react"
import PropTypes from "prop-types"

import "./style.css"

const CenteredGroup = ({children, style, height}) => (<div style={{height}} className="centered-group-container">
    <div className="centered-group" style={style}>
        {children}
    </div>
</div>);

CenteredGroup.propTypes = {
    style: PropTypes.object,
    children: PropTypes.arrayOf(React.Component) | null
};

export {
    CenteredGroup
}