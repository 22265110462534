import React from "react";

import './style.css'

const HeadView = () => (<div className="head-view ">
    <img src="https://midnight.screenz.live/midnight-live.png"/>
</div>);

export {
    HeadView
}
