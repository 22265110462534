import React, {useLayoutEffect, useRef} from "react";
import {CenteredGroup} from "../../components/CenteredGroup";

import './style.css'

const isBrowser = typeof window !== `undefined`

const getScrollPosition = ({element, useWindow}) => {
    if (!isBrowser) return {x: 0, y: 0};

    const target = element ? element.current : document.body;
    const position = target.getBoundingClientRect();

    return useWindow
        ? {x: window.scrollX, y: window.scrollY}
        : {x: position.left, y: position.top}
};

export function useScrollPosition(effect, deps, element, useWindow, wait) {
    const position = useRef(getScrollPosition({useWindow}));

    let throttleTimeout = null;

    const callBack = () => {
        const currPos = getScrollPosition({element, useWindow});
        effect({prevPos: position.current, currPos});
        position.current = currPos;
        throttleTimeout = null
    };

    useLayoutEffect(() => {
        const handleScroll = () => {
            if (wait) {
                if (throttleTimeout === null) {
                    throttleTimeout = setTimeout(callBack, wait)
                }
            } else {
                callBack()
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll)
    }, deps)
}

export const Footer = ({marginTop}) => {
    return (<div className="footer" style={{marginTop}}>
        <CenteredGroup style={{alignContent: 'center'}}>
            <img width={91} height={19} id="live-nation" src="https://midnight.screenz.live/live-nation-logo.png"/>
            <img width={43} height={16} id="peach" src="https://midnight.screenz.live/peach-logo.png"/>
            <img width={15} height={20} id="music-tlv" src="https://midnight.screenz.live/music-tlv-logo.png"/>
            <img width={50} height={30} id="screenz-live" src="https://midnight.screenz.live/screenz-live-logo.svg"/>
            <img width={61} height={25} id={"tripping"} src="https://midnight.screenz.live/tripping-logo.png"/>
        </CenteredGroup>
    </div>);
};
