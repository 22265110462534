const getImageSize = blob => {
    return new Promise(resolve => {
        const fr = new FileReader();
        const img = new Image();

        fr.onload = () => img.src = fr.result.toString();
        img.onload = () => {
            console.log('resolving for the following data ', img);
            resolve({width: img.width, height: img.height});
        };

        fr.readAsDataURL(blob);
    });
};

const getVideoSize = blob => {
    return new Promise(function (resolve) {
        resolve({
            width: 0, height: 0
        })
    });
};


export const getMediaSize = async blob => {
    console.log('finding media size');
    if (/^image/.test(blob.type)) return await getImageSize(blob);
    if (/^video/.test(blob.type)) return await getVideoSize(blob);
    return null
};

export const getBlobType = blob => {
    console.log(blob.type);
    return blob.type.toString().split('/')[0];
};

export const insideIframe = () => window.self !== window.top;

