import * as firebase from "firebase"
import "firebase/auth"
import {firebaseApp} from "./index";
import {insideIframe} from "../../util";

const googleProvider = new firebase.auth.GoogleAuthProvider();

const facebookProvider = new firebase.auth.FacebookAuthProvider();

const signOut = () => firebase.auth().signOut();

export const auth = firebase.auth();

const signInWith = async (provider) => {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
    if (insideIframe()) {
        return await auth.signInWithPopup(provider);
    } else {
        return await auth.signInWithRedirect(provider);
    }
};

export {
    googleProvider,
    facebookProvider,
    signOut,
    signInWith,
}