import React, {useEffect, useRef} from "react"
// import videojs from "video.js"

export const VideoPlayer = ({id, width, height, videoSrc}) => {

    useEffect(() => {
        const options = {
            fill: true,
            fluid: true,
            preload: 'auto',
            html5: {
                hls: {
                    enableLowInitialPlaylist: true,
                    smoothQualityChange: true,
                    overrideNative: true,
                },
            },
        };
        if (window.videojs) {
            const player = window.videojs(id);
            player.nuevo({
                relatedMenu: false,
                shareMenu: false,
                rateMenu: false,
                zoomMenu: false,
                autoplay: true
            });

            return () => player.dispose();
        }
    }, []);

    return <div style={{maxWidth: '70vw', width: '90%', maxHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <video id={id}
               className="video-js vjs-fluid vjs-default-skin"
               controls preload="auto" disablePictureInPicture autoPlay playsinline webkit-playsinline>
            <source src="https://reshet-live-il.ctedgecdn.net/13tv-app/nodvr/r13.m3u8"/>
        </video>
    </div>
};
