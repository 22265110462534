import React from "react";

import {FileButtons} from "./form/FileButtons";
import {TextInput} from "./form/TextInput";
import {EmojiInput} from "./form/EmojiInput";

import './form/style.css'

const FormView = ({style}) => (<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1, justifyContent: 'space-around'}} className="view-container">
    <FileButtons/>
    <EmojiInput/>
    <TextInput/>
</div>);

export {
    FormView
}