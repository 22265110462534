import React from "react";
import PropTypes from "prop-types";
import "./style.css"

const RoundButton = ({label, dir, style, onClick, children}) => {
    return <div style={style} className="round-button-container">
        <div onClick={onClick} className="round-button">{children}</div>
        <label dir={dir} className="round-button-label">{label}</label>
    </div>
};

RoundButton.propTypes = {
    label: PropTypes.string,
    dir: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.arrayOf(React.Component),
};

export {
    RoundButton
}