import * as firebase from "firebase";
import "firebase/storage"
import {getBlobType, getMediaSize} from "../../util";

const storage = firebase.storage();
const rootRef = storage.ref('/');
const systemRef = rootRef.child('system');
const emojiRef = systemRef.child('emojis');
const profileRef = systemRef.child('profiles');

const storeFile = (user, name, blob) => {
    const {uid} = user;

    const url = `${uid}/media/${name}`;
    const ref = rootRef.child(url);
    const task = ref.put(blob);
    const calculate = async () => {
        const downloadURL = await ref.getDownloadURL();
        const type = getBlobType(blob);
        console.log('blob type ', type, 'name ', name);
        const size = blob.size;
        const {width, height} = await getMediaSize(blob);
        return {downloadURL, type, size, width, height, ref: url}
    };
    const promise = task.then(() => calculate());
    return {
        task, promise
    }
};

const storeProfilePhoto = async (uid, blob) => {
    const ref = profileRef.child(`/${uid}/profile.png`);
    await ref.put(blob);
    return await ref.getDownloadURL();
};

const getEmojiURL = async (name) => await emojiRef.child(name).getDownloadURL();

export {
    emojiRef,
    storeFile,
    getEmojiURL,
    storeProfilePhoto
}