import * as firebase from "firebase";
import "firebase/firestore";
import {getEmojiURL} from "./storage";
import {analytics} from "./analytics";

const firestore = firebase.firestore();

const messagesCollection = firestore.collection("messages");

const textCollection = firestore.collection('text');
const emojiCollection = firestore.collection('emoji');
const userCollection = firestore.collection('user');

const sendText = async (user, text) => {
    const {uid, photoURL, displayName} = user;
    const item = {
        uid, photoURL, displayName,
        type: 'text',
        value: text,
        timestamp: firebase.firestore.Timestamp.now(),
    };
    analytics.logEvent("send_message");
    return await textCollection.add(item);
};

const sendFile = async (user, {size, width, height, type, downloadURL, ref}) => {
    const {uid, photoURL, displayName} = user;
    console.log('for media data is ', size, width, height, type, downloadURL);
    const item = {
        uid, photoURL, displayName,
        type, width, height, size, ref,
        value: downloadURL,
        timestamp: firebase.firestore.Timestamp.now()
    };
    analytics.logEvent("upload_media");
    return await firestore.collection("pre_" + type).add(item);
};

const sendEmoji = async (user, name) => {
    const emojiURL = await getEmojiURL(name);

    const {uid, displayName, photoURL} = user;
    const item = {
        uid, photoURL, displayName,
        type: 'emoji',
        value: emojiURL,
        timestamp: firebase.firestore.Timestamp.now(),
    };
    analytics.logEvent("send_emoji");
    return await emojiCollection.add(item);
};

const sendUser = async (user) => {
    const {uid, displayName, photoURL} = user;

    const item = {
        uid, displayName, photoURL,
        type: 'user',
        value: photoURL,
        timestamp: firebase.firestore.Timestamp.now()
    };

    return await userCollection.add(item);
};

export {
    sendText, sendFile, sendEmoji, sendUser
}