import React from "react"

import {HeadView} from "../views/HeadView";
import {AuthenticationView} from "../views/AuthenticationView";
import {TimerView} from "../views/TimerView";
import {useSnackbarStore} from "../context/SnackbarStore";
import {Footer} from "../views/Footer";
import {TermsOfServiceContext, useTermsOfServiceStore} from "../context/TermsOfServiceStore";
import {TermsCheckbox} from "../components/TermsCheckbox";

const targetTimestamp = Date.UTC(2020, 4, 28, 17, 0);

export const AuthenticationPage = () => {

    const termsStore = useTermsOfServiceStore();
    const snackbarStore = useSnackbarStore();

    return (<div className="content-page">
                <TermsOfServiceContext.Provider value={termsStore}>
                    <div style={{display: 'flex', maxWidth: '100%', width: '650px', flexDirection: 'column', minHeight: '100%', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1, width: '100%', height: '100%'}}>
                            <HeadView/>
                            <div style={{display: 'flex', flexDirection: 'column', flexGrow: '1', justifyContent: 'center', alignItems: 'center', width: "100%", height: '100%'}}>
                                <div style={{width: '100%'}} className="mobile-only-screenz">
                                    <TimerView targetTimestamp={targetTimestamp}/>
                                </div>
                                <h1 className="base-font desktop"  style={{fontWeight: 'normal'}} dir={"rtl"}>התחברו!</h1>
                                <p style={{width: '80vw', textAlign: 'center', marginTop: '15px', maxWidth: '300px'}} dir={"rtl"} className="base-font">שימו על עצמכם משהו נחמד, ריקדו עכשיו מול המצלמה ובערב יום העצמאות אולי תראו את עצמכם רוקדים מול כל המדינה במסיבה האינטרקטיבית הגדולה</p>
                                <AuthenticationView/>
                            </div>
                        </div>
                        <TermsCheckbox/>
                        <Footer marginTop={'0'}/>
                    </div>
                </TermsOfServiceContext.Provider>
        </div>
    )
};
