import React, {useState} from "react";
import {storeProfilePhoto} from "../apis/firebase";


export const UserStoreContext = React.createContext({});

const loadUser = () => {
    const str = localStorage.getItem("user");
    if (str) return JSON.parse(str);
    else return null;
};

const saveUser = user => localStorage.setItem('user', JSON.stringify(user));

export const useUserStore = () => {
    const [user, setUserState] = useState(loadUser());

    const setUser = async user => {
        const blob = await (await fetch(user.photoURL)).blob();
        const photoURL = await storeProfilePhoto(user.uid, blob);
        const newUser = {uid: user.uid, displayName: user.displayName, photoURL};
        setUserState(newUser);
        saveUser(newUser);
    };

    return {user, setUser}
};