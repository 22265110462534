import React, {useContext} from "react";
import PropTypes from "prop-types"

import {Icon} from "semantic-ui-react";
import {signInWith} from "../apis/firebase"
import {RoundButton} from "./RoundButton";
import {SnackbarStoreContext} from "../context/SnackbarStore";
import {TermsOfServiceContext} from "../context/TermsOfServiceStore";

const SignInButton = ({icon, provider, disabled, onUser}) => {
    const snackbarStore = useContext(SnackbarStoreContext);
    const termsStore = useContext(TermsOfServiceContext);
    const signIn = () => {
        if (!disabled) signInWith(provider).then(() => console.log('provider done')).then(result => onUser(result.user));
        else {
            termsStore.setDisplayWarning(true);
        }
    };
    return (
        <RoundButton onClick={signIn}><img src={icon + ".svg"}/></RoundButton>
    )
};

SignInButton.propTypes = {
    icon: PropTypes.string,
    provider: PropTypes.object,
    onUser: PropTypes.func,
};


export {
    SignInButton
}