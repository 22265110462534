import React from "react"
import {Timer} from "../components/Timer";

import {eventTime} from "../App";

export const TimerView = () => (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%'
    }}>
        <h1 className="base-font" style={{fontWeight: 'normal'}} dir={"rtl"}>האירוע מתחיל בעוד</h1>
        <Timer targetTimestamp={eventTime}/>
        <h1 className="base-font" style={{fontWeight: 'normal'}} dir={"rtl"}>כיף שהצטרפתם!</h1>
    </div>
);