import React, {useContext} from "react"

import {FileInputButton} from "../../components/FileInputButton";
import {CenteredGroup} from "../../components/CenteredGroup";
import {sendFile, storeFile} from "../../apis/firebase";
import {UserStoreContext} from "../../context/UserStore";
import {SnackbarStoreContext} from "../../context/SnackbarStore";


export const FileButtons = () => {
    const userStore = useContext(UserStoreContext);
    const snackbarStore = useContext(SnackbarStoreContext);
    const {percent, setPercent} = snackbarStore;

    const onFile = file => {
        const {task, promise} = storeFile(userStore.user, file.name, file.blob);
        snackbarStore.progress();
        promise.then(data => {
            sendFile(userStore.user, data)
                .then(() => {
                    snackbarStore.success();
                });

        });

        task.on("state_changed", ({bytesTransferred, totalBytes}) => {
            setPercent(100 * bytesTransferred / totalBytes);
        })
    };

    return (
        <div className="">
            <h2 style={{fontWeight: 'normal'}} className="base-font" dir={"rtl"}>בואו נראה אתכם</h2>
            <CenteredGroup>
                <FileInputButton dir={"rtl"} label={"תמונה"} icon={"pic.svg"} accept={"image/png,image/jpeg"}
                                 onFile={onFile}/>
                <FileInputButton dir={"rtl"} style={{marginLeft: '30px'}} label={"וידאו"} icon={"video.svg"} accept={"video/*"}
                                 onFile={onFile}/>
            </CenteredGroup>
        </div>
    )
};