import React, {useContext, useEffect} from "react";
import {SignInButton} from "../../components/SignInButton";

import {analytics, facebookProvider, googleProvider, sendUser} from "../../apis/firebase";
import {UserStoreContext} from "../../context/UserStore";
import {auth} from "../../apis/firebase/auth"
import "./style.css"
import {CenteredGroup} from "../../components/CenteredGroup";
import {TermsOfServiceContext} from "../../context/TermsOfServiceStore";

export const AuthenticationView = () => {

    const context = useContext(UserStoreContext);
    const termsStore = useContext(TermsOfServiceContext);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) context.setUser(user)
                .then(() => console.log('logged in successfully'))
        })
    });
    const login = method => user => {
        if (termsStore.accepted) sendUser(user)
            .catch(e => console.error(e))
            .then(() => {
                analytics.logEvent('login', {
                    method
                })
            });
    };

    return (<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', textAlign: 'center'}}>
        <CenteredGroup style={{marginTop: '20px'}}>
            <SignInButton disabled={!termsStore.accepted} icon={"google"} provider={googleProvider} onUser={login('google')}/>
            <label dir={"rtl"} className="authentication-view-label">או</label>
            <SignInButton disabled={!termsStore.accepted} icon={"facebook"} provider={facebookProvider} onUser={login('facebook')}/>
        </CenteredGroup>
    </div>)
};
