import React, {useContext, useEffect} from "react"
import {TermsOfServiceContext} from "../../context/TermsOfServiceStore";

export const TermsCheckbox = () => {

    const termsStore = useContext(TermsOfServiceContext);
    useEffect(() => {
        if (termsStore.accepted) termsStore.setDisplayWarning(false);
    });
    return (
        <div style={{display: 'flex', flexDirection: 'column', marginTop: 'auto', alignItems: 'center'}}>
            <p style={{color: 'red', visibility: termsStore.displayWarning ? 'visible' : 'hidden'}}>עליך לאשר את תנאי השימוש על מנת להירשם</p>
            <div className="base-font" dir={"rtl"} style={{marginTop: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <input style={{marginRight: '5px'}} type={"checkbox"} checked={termsStore.accepted} onChange={e => termsStore.setAccepted(e.target.checked)}/>
                <p className="base-font" style={{marginRight: '5px'}}>
                    אני מאשר שקראתי את
                    <a href="/terms.pdf" target="_blank"> תנאי השימוש </a>
                </p>
            </div>
        </div>
    )
};