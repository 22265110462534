import React, {useContext, useEffect} from "react";
import {LogoView} from "../views/LogoView";
import {ContentPage} from "./ContentPage";
import {VideoPage} from "./VideoPage";
import {UserStoreContext} from "../context/UserStore";
import {AuthenticationPage} from "./AuthenticationPage";

import './style.css'
import {PreviewPage} from "./PreviewPage";
const PageSelector = () => {
    const store = useContext(UserStoreContext);
    return (!store.user ? <AuthenticationPage/> : <ContentPage/>)
};

export const PageContainer = () => {
    const store = useContext(UserStoreContext);

    useEffect(() => {
        console.log('user is ', !!store.user);
    });
    return (<div className="page" style={{display: 'flex', flexDirection: 'column'}}>
        <LogoView/>
        <div className="main-page">
            {window.location.pathname.includes('testing') ? <React.Fragment>
                <PageSelector/>
                <VideoPage/>
            </React.Fragment> : <PreviewPage/>}
        </div>
    </div>)
};