import React, {useState, useEffect} from "react"
import {TimerView} from "../views/TimerView"
import {VideoView} from "../views/VideoView";

import {eventTime} from "../App";

const ViewSelector = () => {

    const [time, setTime] = useState(new Date().getTime());

    useEffect(() => {
        const i = setInterval(() => {
            setTime(new Date().getTime())
        }, 100);

        return () => clearInterval(i);
    });
    return <React.Fragment>{eventTime > time ? <TimerView/>: <VideoView/>}</React.Fragment>

};
export const VideoPage = () => (
    <div className="desktop" style={{flexGrow: '1'}}>
        <div className="video-page">
            <ViewSelector/>
        </div>
    </div>
);