import React from "react"
import {SnackbarStoreContext, useSnackbarStore} from "../context/SnackbarStore";
import {HeadView} from "../views/HeadView";
import {FormView} from "../views/FormView";
import {Snackbar} from "../components/Snackbar";
import {Footer} from "../views/Footer";

export const ContentPage = () => {

    const snackbarStore = useSnackbarStore();

    return <div className="content-page">
        <SnackbarStoreContext.Provider value={snackbarStore}>
            <div style={{display: 'flex', flexDirection: 'column', maxWidth: '100%', width: '650px', minHeight: '100%', flexGrow: '1'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%', height: '100%'}}>
                    <HeadView/>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center', width: "100%", height: '100%'}}>
                        <FormView/>
                    </div>
                </div>
                <Footer marginTop={'0'}/>
            </div>
            <Snackbar open={snackbarStore.open} timeout={snackbarStore.timeout} height={snackbarStore.height} setOpen={snackbarStore.setOpen}>
                {snackbarStore.component}
            </Snackbar>
        </SnackbarStoreContext.Provider>
    </div>
};
