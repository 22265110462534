import React, {useEffect, useState} from "react"
import {CenteredGroup} from "../../components/CenteredGroup";

import "./style.css"

const duration = (a, b) => {
    let diff = (a - b) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
        years: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
        timeLeft.years = Math.floor(diff / (365.25 * 86400));
        diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) { // 24 * 60 * 60
        timeLeft.days = Math.floor(diff / 86400);
        diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) { // 60 * 60
        timeLeft.hours = Math.floor(diff / 3600);
        diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
        timeLeft.min = Math.floor(diff / 60);
        diff -= timeLeft.min * 60;
    }
    timeLeft.sec = parseInt(diff);
    return timeLeft;
};

const TimerItem = ({value, label}) => (<div className=" base-font timer-item">
    <h1 className="base-font">{value >= 10 ? value : "0" + value}</h1>
    <label dir={"rtl"}>{label}</label>
</div>);

export const Timer = ({targetTimestamp}) => {

    const [current, setCurrent] = useState(Date.now());
    useEffect(() => {
        setInterval(() => setCurrent(Date.now), 1000);
    }, []);

    const {days, hours, min, sec} = duration(targetTimestamp, current);
    return (<CenteredGroup>
        <TimerItem value={days && days > 0 ? days : 0} label={"ימים"}/>
        <TimerItem value={hours && hours > 0 ? hours: 0} label={"שעות"}/>
        <TimerItem value={min && min > 0 ? min : 0} label={"דקות"}/>
        <TimerItem value={sec && sec > 0 ? sec : 0} label={"שניות"}/>
    </CenteredGroup>)
};
