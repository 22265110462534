import React from "react"

import {HeadView} from "../views/HeadView";


export const PreviewPage = () => (
    <div style={{alignItems: 'center', display: 'flex', width: '100%', height: '100%', flexDirection: 'column'}}>
        <HeadView/>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: '1'}}>
            <h1 style={{fontWeight: 'normal', textAlign: 'center'}} dir={"rtl"}>
                 תודה רבה שהרמתם איתנו!
            </h1>
            <h2 className={"base-font"} style={{margin: '0', fontWeight: 'normal', textAlign: 'center'}} dir={"rtl"}>
                מאחלים לכם התאוששות קלה מההנג-אובר ומצפים לראות אתכם במסיבה הבאה.
            </h2>
            <h2  className={"base-font"} style={{margin: '0', fontWeight: 'normal', textAlign: 'center'}} dir={"rtl"}>
                פרטים בהמשך
            </h2>
        </div>
    </div>
);