import React, {useContext} from "react";
import {CenteredGroup} from "../../components/CenteredGroup";
import {EmojiButton} from "../../components/EmojiButton";
import {UserStoreContext} from "../../context/UserStore";
import {sendEmoji} from "../../apis/firebase";
import {SnackbarStoreContext} from "../../context/SnackbarStore";

export const EmojiInput = () => {
    const userStore = useContext(UserStoreContext);
    const snackbarStore = useContext(SnackbarStoreContext);

    const send = name => sendEmoji(userStore.user, name)
        .then(() => snackbarStore.success())
        .catch(e => {
            console.error(e);
            alert("couldn't upload emoji");
        });

    return (<div className="form-space">
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <h2 style={{color: 'white', fontWeight: 'normal', marginBottom: '2px'}} dir={"rtl"}>בואו נרגיש אתכם</h2>

            <CenteredGroup>
                <EmojiButton style={{marginTop: '5px'}} name={"fire"} onClick={send}/>
                <EmojiButton style={{marginLeft: '10px', marginTop: '5px'}} name={"smile"} onClick={send}/>
                <EmojiButton style={{marginLeft: '10px', marginTop: '5px'}} name={"heart"} onClick={send}/>
                <EmojiButton style={{marginLeft: '10px', marginTop: '5px'}} name={"drink"} onClick={send}/>
                <EmojiButton style={{marginLeft: '10px', marginTop: '5px'}} name={"disco"} onClick={send}/>
                <EmojiButton style={{marginLeft: '10px', marginTop: '5px'}} name={"flag"} onClick={send}/>
            </CenteredGroup>
        </div>
    </div>);
};